import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {PublicClientApplication} from "@azure/msal-browser";
import MindsetsJsSDK, {rolesConstants, CustomDropdown} from 'mindsets-js-sdk';

import ToBuyButton from 'common/membership/ToBuyButton'
import MobileNavMenu from "common/MobileNavMenu";
import analytic from 'utils/Analytic'
import {isEducator, isLearner} from "utils/role";
import {userCan} from "utils/membership";

import './user_menu.scss';
import {LogoutAction} from "../../../actions/login_actions";

const msalInstance = new PublicClientApplication({
    auth: {
        clientId: process.env.PUBLIC_OFFICE_OAUTH_ID
    }
});
await msalInstance.initialize();

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);

    function renderBuyMembership(btn_class) {
        return (
            <ToBuyButton button_class_name={btn_class}/>
        )
    }

    function renderAvatar() {
        return (<div className={"cashtivity-icon " + me.avatar}/>);
    }

    function renderMenuItemHome() {
        const onClick = () => analytic.log('navigateViaUserMenu', '/home')
        return (
            <Link to="/" onClick={onClick}>{me.persona === 'student' ? 'Dashboard' : 'Library'}</Link>
        )
    }

    function renderMenuItemProfile() {
        const onClick = () => analytic.log('navigateViaUserMenu', '/profile')
        return (
            <Link to="/profile" onClick={onClick}>{`My Account`}</Link>
        )
    }

    function renderPD() {
        if (me.persona === rolesConstants.ROLE_TEACHER) {
            const onClick = () => analytic.log('navigateViaUserMenu', '/teacher/pd/entry')
            return (
                <Link to="/teacher/pd/entry" onClick={onClick}>{`Get Certified`}</Link>
            )
        }

        return null
    }

    function renderSAM() {
        if (isEducator(me) && me.permissions.includes('can_see_sam') && userCan(me.subscription_status, 'see_sam')) {
            const onClick = () => analytic.log('navigateViaUserMenu', '/engagement/overview')
            return (
                <Link to="/engagement/overview" onClick={onClick}>{`Engagement Dashboard`}</Link>
            )
        }

        return null;
    }

    function renderPAM() {
        // Hide old PAM for now
        return null;
        if (isEducator(me) && me.permissions.includes('can_see_pam') && userCan(me.subscription_status, 'see_pam')) {
            return (
                <Link to="/pam_blu">{`PAM Blu`}</Link>
            )
        }

        return null;
    }

    function renderInsights() {
        if (isEducator(me)) {
            const onClick = () => analytic.log('navigateViaUserMenu', '/insights')
            return (
                <Link to="/insights" onClick={onClick}>{`Insights Dashboard`}</Link>
            )
        }

        return null;
    }

    function renderPDLibrary() {
        if (isEducator(me) && (process.env.PUBLIC_APP_ENV != 'production' || me.is_dashboard_super_admin)) {
            const onClick = () => analytic.log('navigateViaUserMenu', '/teacher/pd-courses')
            return (
                <Link to="/teacher/pd-courses" onClick={onClick}>{`PD Courses`}</Link>
            )
        }

        return null;
    }

    function renderMenuItemTeacherContactUs() {
        if (isEducator(me)) {
            const onClick = () => {
                analytic.log('navigateViaUserMenu', '/teacher/contact-us')
            }
            return (
                <Link to="/teacher/contact-us" onClick={onClick}>{`Contact Us`}</Link>
            )
        }

        return null;
    }

    function renderMenuItemTeacherHelp() {
        if (isEducator(me)) {
            const onClick = () => analytic.log('navigateViaUserMenu', 'https://fast.wistia.com/embed/channel/sc32gs6ywb')
            return (
                <a href="https://fast.wistia.com/embed/channel/sc32gs6ywb" onClick={onClick} target='_blank'>{`Resources`}</a>
            )
        }

        return null;
    }

    function renderMenuItemLogout() {
        const onClick = (e) => {
            e.preventDefault();

            analytic.log('navigateViaUserMenu', '/logout');
            analytic.log('logout');

            const accounts = msalInstance.getAllAccounts();
            dispatch(LogoutAction()).then(() => {
                MindsetsJsSDK().Auth.logout();
                if (accounts.length > 0 && confirm('Do you also want to sign out of your Microsoft account?')) {
                    msalInstance.logoutRedirect({
                        account: accounts[0],
                        postLogoutRedirectUri: window.location.origin
                    });
                } else {
                    window.location.href = '/';
                }
            });
        };

        return (
            <a href="#" onClick={onClick}>{`Sign Out`}</a>
        )
    }

    function renderDesktopMenu() {
        return (
            <div className='hide-on-med-and-down'>
                <CustomDropdown
                    trigger={renderAvatar()}
                    selected={window.location.pathname}
                    no_wrap={true}
                    items={{
                        '/student/home': isLearner(me) ? renderMenuItemHome() : null,
                        '/teacher/home': isEducator(me) ? renderMenuItemHome() : null,
                        '/profile': renderMenuItemProfile(),
                        '/teacher/pd/entry': renderPD(),
                        '/engagement/overview': renderSAM(),
                        '/pam_blu': renderPAM(),
                        '/insights': renderInsights(),
                        '/teacher/pd-courses': renderPDLibrary(),
                        '/teacher/contact-us': renderMenuItemTeacherContactUs(),
                        resources: renderMenuItemTeacherHelp(),
                        divider: <hr/>,
                        logout: renderMenuItemLogout()
                    }}
                    use_arrow={false}
                />
            </div>
        )
    }

    function renderMobileMenu() {
        return (
            <MobileNavMenu class_name='hide-on-large-only'>
                <div className="section">
                    <div className="flex-row flex-vh-center">
                        {renderAvatar()}
                    </div>
                </div>

                <div className="flex-row flex-vh-center">
                    {renderBuyMembership()}
                </div>

                <div className="divider"/>

                <ul>
                    <li className={window.location.pathname === (isEducator(me) ? '/teacher/home' : '/student/home') ? 'selected' : ''}>{renderMenuItemHome()}</li>
                    <li className={window.location.pathname === '/profile' ? 'selected' : ''}>{renderMenuItemProfile()}</li>
                    <li className={window.location.pathname === '/pd/entry' ? 'selected' : ''}>{renderPD()}</li>
                    <li className={window.location.pathname === '/engagement/overview' ? 'selected' : ''}>{renderSAM()}</li>
                    <li className={window.location.pathname === '/pam_blu' ? 'selected' : ''}>{renderPAM()}</li>
                    <li className={window.location.pathname === '/insights' ? 'selected' : ''}>{renderInsights()}</li>
                    <li className={window.location.pathname === '/teacher/pd-courses' ? 'selected' : ''}>{renderPDLibrary()}</li>
                    <li className={window.location.pathname === '/teacher/contact-us' ? 'selected' : ''}>{renderMenuItemTeacherContactUs()}</li>
                    <li>{renderMenuItemTeacherHelp()}</li>
                    <li className="divider" tabIndex="-1"/>
                    <li>{renderMenuItemLogout()}</li>
                </ul>
            </MobileNavMenu>
        );
    }

    if (me) {
        return (
            <div className='user-menu'>
                {renderDesktopMenu()}
                {renderMobileMenu()}
            </div>
        );
    }

    return null;
}
