import React from 'react'
import {get, isEmpty} from 'lodash'

import BaseCard from './BaseCard'
import {processStringContent} from 'utils/content'
import AnswerKeyButton from 'common/challenge/AnswerKeyButton'


export default class CompleteCard extends BaseCard {
    getCardType() {
        return 'complete'
    }

    useChallengeColor() {
        return true
    }

    renderAnswerKey() {
        if (!this.props.challenge_version.answer_key_url) {
            return null
        }

        const is_answer_keys_shown = get(this.props.lesson, 'is_answer_keys_shown')
        if (!isEmpty(this.props.lesson) && !is_answer_keys_shown) {
            return null
        }

        return (
            <div className="section">
                <AnswerKeyButton
                    challenge_version={this.props.challenge_version}
                    lesson={this.props.lesson}
                    is_preview={this.props.is_preview}
                    is_bonus={false}
                />

                <div className="complete-card-answer-key-message mt-2 center-align">
                    {`Use the Answer Key to check your work.`}
                </div>
            </div>
        )
    }

    renderMessageStarter() {
        if (this.props.challenge_version.challenge_code.is_pd) {
            return (
                <h3 className="header-new">
                    {this.props.challenge_version.name}
                </h3>
            );
        } else {
            return (
                <div>
                    {`In this Challenge...`}
                </div>
            );
        }
    }

    renderOtherContent() {
        return (
            <div className="flex-column flex-v-center">
                {this.renderAnswerKey()}

                <div className="section"></div>

                {this.renderMessageStarter()}

                <div className="complete-card-content">
                    {
                        processStringContent(this.props.stream_entity.data.content, {
                            className: "flex-column flex-v-center center-align"
                        })
                    }
                </div>
            </div>
        )
    }
}
